import data from "../data/pages";

const PreloadFonts = () => {
    return (
        <div className="w-0 h-0 fixed top-0 left-0 overflow-hidden">
            {data.map((item) => (
                <div key={item.title} style={{ fontFamily: item.font }}>
                    x
                </div>
            ))}
        </div>
    );
};

export { PreloadFonts };
