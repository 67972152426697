import { Routes, Route } from "react-router-dom";
import { useState } from "react";

import { Menu } from "./components/Menu";
import { Monu } from "./components/Monu";
import { PreloadFonts } from "./components/PreloadFonts";

function App() {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <div>
            <Menu setCurrentSlide={setCurrentSlide} />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Monu
                            setCurrentSlide={setCurrentSlide}
                            currentSlide={currentSlide}
                        />
                    }
                />
                <Route
                    path="/m/:index"
                    element={
                        <Monu
                            setCurrentSlide={setCurrentSlide}
                            currentSlide={currentSlide}
                        />
                    }
                />
            </Routes>
            <PreloadFonts />
        </div>
    );
}

export default App;
