import { useParams, useNavigate } from "react-router-dom";
import { Page } from "./Page";
import { useState, useEffect } from "react";

import data from "../data/pages";

const Monu = ({ setCurrentSlide, currentSlide }) => {
    const { index: origIndex } = useParams();
    const index = origIndex || 0;
    const navigate = useNavigate();
    const [hasMovedBackwards, setHasMovedBackwards] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const monu = data[index || 0];

    useEffect(() => {
        if (isTransitioning) {
            setTimeout(() => setIsTransitioning(false), 300);
        }
    }, [isTransitioning, setIsTransitioning]);

    useEffect(() => {
        if (hasMovedBackwards !== false) {
            setHasMovedBackwards(false);
            setCurrentSlide(data[hasMovedBackwards].content.length - 1);
            setIsTransitioning(true);
        }
    }, [hasMovedBackwards, setCurrentSlide]);

    return (
        <div className={`z-10`}>
            <div className="fixed left-0 w-full px-4 pb-4 text-center uppercase top-4">
                <div className="">
                    {monu.title.split(" ").length > 2 &&
                    monu.title !== "Monument to Coranderrk" ? (
                        <>
                            {monu.title.match(/^(\w+)\s(\w+)/)[0]} &hellip;{" "}
                            {monu.title.match(/(\w+)$/)[0]}
                        </>
                    ) : (
                        monu.title
                    )}
                </div>
            </div>
            {!isTransitioning && (
                <Page
                    content={monu.content[currentSlide]}
                    title={currentSlide === 0 ? monu.title : ""}
                    style={{
                        fontFamily: monu.font,
                        letterSpacing: "0.5px",
                    }}
                />
            )}
            <div className="fixed top-4 right-4">
                {currentSlide + 1} ⁄ {monu.content.length}
            </div>
            <div className="fixed bottom-0 left-0 items-center justify-center hidden w-full p-2">
                {monu.content.map((page, index) => {
                    return (
                        <button
                            className={`p-2 hover:opacity-25 ${
                                index === currentSlide && "opacity-25"
                            }`}
                            key={index}
                            onClick={() => {
                                setIsTransitioning(true);
                                setCurrentSlide(index);
                            }}
                        >
                            {index + 1}
                        </button>
                    );
                })}
            </div>
            <button
                className="fixed left-0 w-1/2 top-12 bottom-12"
                onClick={(e) => {
                    if (currentSlide === 0) {
                        const newIndex =
                            index === "0" ? data.length - 1 : index - 1;
                        setIsTransitioning(true);
                        navigate(`/m/${newIndex}`);
                        setHasMovedBackwards(newIndex);
                    } else {
                        setIsTransitioning(true);
                        setCurrentSlide(currentSlide - 1);
                    }
                }}
            ></button>
            <button
                className="fixed right-0 w-1/2 top-12 bottom-12"
                onClick={(e) => {
                    if (currentSlide === monu.content.length - 1) {
                        const newIndex =
                            Number(index) === Number(data.length - 1)
                                ? 0
                                : Number(index) + 1;

                        setIsTransitioning(true);
                        navigate(`/m/${newIndex}`);
                        setCurrentSlide(0);
                    } else {
                        setIsTransitioning(true);
                        setCurrentSlide(
                            Number(currentSlide) ===
                                Number(monu.content.length - 1)
                                ? 0
                                : Number(currentSlide + 1)
                        );
                    }
                }}
            ></button>
        </div>
    );
};

export { Monu };
