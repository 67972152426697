import { useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import data from "../data/pages";

const Menu = ({ setCurrentSlide }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    let location = useLocation();

    useEffect(() => {
        setIsMenuOpen(false);
        setCurrentSlide(0);
    }, [location, setCurrentSlide]);

    return (
        <div className="relative z-20">
            <div
                className={`fixed ${
                    isMenuOpen ? "top-0 opacity-100" : "top-full opacity-0"
                } left-0 w-full h-full bg-[#1A1919] p-4 transition-all duration-300 overflow-y-auto flex flex-col`}
            >
                <div className="pb-4 text-center uppercase">
                    Chimney In Store
                </div>
                <div className="max-w-md pb-24 mx-auto">
                    {data.map((monument, index) => (
                        <div
                            key={index}
                            className="pl-8 tracking-tight -indent-8 md:tracking-normal whitespace-nowrap"
                        >
                            <Link
                                className={`hover:opacity-25 ${
                                    `/m/${index}` === location.pathname
                                        ? "opacity-25"
                                        : ""
                                }`}
                                to={`/m/${index}`}
                            >
                                {monument.title}
                            </Link>
                        </div>
                    ))}
                </div>
                {isMenuOpen && (
                    <a
                        class="absolute top-0 right-0 p-4"
                        href="https://acca.melbourne/exhibition/tom-nicholson-chimney-in-store"
                        target="_blank"
                        rel="noreferrer"
                    >
                        ABOUT
                    </a>
                )}
            </div>
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={`${
                    isMenuOpen ? "block lg:block" : "block lg:block"
                } fixed top-0 left-0 uppercase p-4 hover:opacity-25 active:opacity-100 focus:opacity-100`}
            >
                {isMenuOpen ? "Close" : "Menu"}
            </button>
        </div>
    );
};

export { Menu };
