import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import image4 from "./image4.png";
import image5 from "./image5.png";

const pages = [
    {
        title: "Monument to Buckley’s correction",
        content: [
            "This monument, this vertical brick structure standing before you, commemorates the chimney built for John Batman by William Buckley. </p><p>Buckley was a British convict who escaped from a short-lived penal colony on Boonwurrung country, at Sorrento, in 1803. He lived with Wathaurung people around the Bellarine Peninsula for over 30 years.",
            "Batman, a grazier living in Van Diemen’s Land, travelled to the future site of Melbourne in 1835, as leader of the Port Phillip Association.</p><p>He claimed he signed a Treaty with Wurundjeri people exchanging 240,000 hectares of Wurundjeri land for 20 pairs of blankets, 30 tomahawks, 100 knives, 50 pairs of scissors, 30 looking glasses, 200 handkerchiefs, 100 pounds of flour and six shirts, as well as an annual tribute of 100 pairs of blankets, 100 knives, 100 tomahawks, 50 suits of clothing, ",
            "50 looking glasses, 50 pairs of scissors and five tons of flour.</p><p>When he heard about Batman’s arrival at Port Phillip Bay, Buckley walked with Pulmadaring and several other Wathaurung kin to the camp that Batman had established at Indented Head, near present-day Geelong. Buckley then rejoined British society and for a time acted as a mediator between Aboriginal communities and settlers from Batman’s Port Phillip Association.",
            "As a trained bricklayer from his life in England before his conviction for theft, Buckley built the chimney for Batman’s house, the first permanent colonial dwelling built in Melbourne. As a British man assimilated into Aboriginal culture, Buckley publicly corrected Batman about the Treaty he claimed he signed with Wurundjeri elders, pointing out that such a conception of land ownership was alien to Wurundjeri society.",
            "These bricks commemorate these two contradictory acts: the building of a structure that would give warmth to Batman’s home, and the refusal of the basis of that home-making.",
        ],
        font: "TN Gaston",
    },
    {
        title: "Monument to Murrangurk’s spear",
        content: [
            "Murrangurk, better known as William Buckley, was born in Macclesfield, England, in 1780, and was apprenticed as a bricklayer. In 1802 he was convicted of receiving cloth knowing it to have been stolen, AND HE WAS transported in the ‘Calcutta’ to the fledgling penal colony established on Boonwurrung country, at Sullivan Bay (present-day Sorrento). </p><p>Buckley escaped shortly before the colony was to be disbanded.",
            "He walked alone for some time, around Narrm, or Port Phillip Bay. In an account written many years later, he wrote that on the Bellarine Peninsula, Wathaurung country, he came upon a ‘mound of earth, with part of a native’s spear stuck upright on the top of it’. He took the spear as a walking stick. </p><p>when Buckley was met and welcomed by a group of Wathaurung men and women THEY NAMED HIM Murrangurk, ‘which I afterwards learnt was the name of a man formerly belonging to their tribe, who had been buried at the spot where I had found ",
            "the piece of spear I still carried with me’. </p><p>Buckley rejoined British society in 1835, upon the arrival of John Batman, author of the confected document known as Batman’s Treaty and often regarded as the founder of the city of Melbourne. Buckley subsequently used his bricklaying skills to build the chimney for Batman’s house, Melbourne’s first colonial dwelling. </p><p>This brick monument, with its simple vertical form, commemorates the spear that gave Buckley a ",
            "name for his new life within Wathaurung society, a commemoration imagined through his bricklaying, the profession with which Buckley began and resumed his life as a British man. ",
        ],
        font: "TN Sleigh",
    },
    {
        title: "Monument to the height of a hill now absent",
        content: [
            "John Batman entered the heads at Narrm, or Port Phillip Bay, on 29 May 1835 as leader of the Port Phillip Association, his venture ultimately triggering invasion and the establishment of Melbourne. ",
            "</p><p>On 6 June 1835 Batman claimed to have exchanged 240,000 hectares of land for trinkets and an annual tribute in a deed with Wurundjeri leaders known as Batman’s Treaty. The British Crown did not accept the sovereignty of Aboriginal people, and regarded Batman’s Treaty as void. ",
            "</p><p>Batman ultimately purchased more modest holdings from the Crown and built Melbourne’s first colonial dwelling on Batman’s Hill. The chimney for this house was built by William Buckley, the escaped convict (originally trained as a bricklayer) who had lived with Wathaurung people for more than ",
            "30 years near present-day Geelong. </p><p>Batman’s house has long since disappeared. Batman’s Hill was cleared to make way for what is now Southern Cross Station. This vertical brick structure imagines Buckley’s chimney for Batman through its inversion: a subterranean chimney, a square well ",
            "which marks the height of that void, the absent Batman’s Hill which once overlooked Narrm.",
        ],
        font: "TN Grimwade",
    },
    {
        title: "Monument to a sovereign chimney",
        content: [
            "The Wurundjeri are the sovereign Aboriginal people of much of the land occupied by the city of Melbourne and OF the land to the north and east of Melbourne. They belong to the Woiwurrung language group and to the Kulin nation, which also consists of four other language groups: Boonwurrung, Wathaurung, Taungurung and Dja Dja Wurrung.",
            "</p><p>THE WURUNDJERI are profoundly affected by the arrival of Europeans at Narrm, or Port Phillip BAY, a rapid process of violence and dispossession precipitated by the arrival of John Batman’s Port Phillip Association in 1835. </p><p>Most Wurundjeri who survive the first two decades of invasion, along with other displaced Aboriginal people, move to Coranderrk, a mission station ",
            "established in 1863 on the upper reaches of the Yarra RIVER near present-day Healesville. </p><p>The organised resistance that develops there centres on protecting the autonomy of the Aboriginal community at Coranderrk. </p><p>Whereas the first petition presented to the Crown by Aboriginal people comes from Flinders Island in 1846, a document created by the Tasmanian Aboriginal ",
            "people who survive the genocide and are transported to Flinders Island in 1835, the first campaign addressed to the Crown by Aboriginal people comes from Coranderrk. It is Led by Ngurungaeta (or headmen) like Simon Wonga and William Barak, or Beruk, and is supported by the Presbyterian lay preacher John Green. this campaign from the 1860s onwards is sustained by a series of diplomatic means",
            "and deputations, designed to assert Aboriginal rights and claims to land. </p><p>The growing militancy of Aboriginal self-organisation at Coranderrk leads to the implementation of ‘the Aborigines Protection Act 1886’ (or the ‘Half-caste Act’, as it is better known). This legislation anticipates the terms of stolen generations legislation around Australia in the years THAT follow and",
            "is designed to disperse well-organised communities like Coranderrk. </p><p>Though it is vigorously opposed by communities such as Coranderrk and Lake Condah, the Act is successful in dismantling various militant Aboriginal communities. Coranderrk is formally closed in 1924. Most residents are forced to move to Lake Tyers Mission, situated in East Gippsland, several hundred  ",
            "kilometres from Wurundjeri lands (and also remote from Melbourne, making further political agitation more difficult). </p><p>In December 1892, the German amateur anthro-pologist Arthur Baessler visits Coranderrk. he meets the great Wurundjeri Ngurungaeta and artist Barak. Baessler transcribes into his diary part of Barak’s incomplete auto-<br/>biography, an account that includes the  ",
            "extraordinary arc of violence and dispossession that Barak witnesses during his lifetime, beginning with Barak’s memory of the arrival of John Batman at Port Phillip, and the meeting on 6 June 1835 at which Batman claims he signs a deed with Wurundjeri elders, now known as Batman’s Treaty. </p><p>In his diary, Baessler writes of being deeply affected by meeting Barak. ",
            "he remarks on a significant detail in Barak’s dwelling at Coranderrk: the image of Queen Victoria, attached to the chimney in Barak’s living room. The head of one polity displays his respect for another, asserting his own sovereignty by acknowledging another, precisely on the hearth of his chimney, where he welcomes a stranger from another place into his home. ",
            "</p><p>This free-standing chimney commemorates this small detail from Baessler’s diary. It is a monument to remember Barak’s home and its hearth.",
        ],
        font: "TN Flagstaff",
    },
    {
        title: "Monument to a chimney for J. Batman by W. Buckley",
        content: [
            "To commemorate the chimney built by William Buckley, ‘wild white man’, Murrangurk to the Wathaurung, for John Batman, author of Batman’s Treaty, the document of Melbourne’s origins, the beginning of an invasion, the city’s first colonial hearth.",
        ],
        font: "TN Councillor",
    },
    {
        title: "Monument to an imaginary meeting",
        content: [
            "most of the surviving images of the escaped convict william buckley depict one short moment in his life: 2pm sunday 6 july 1835. buckley and a group of his wathaurung kin arrive at a camp established by john batman (the man we know from batman’s treaty, the fabricated document that precipitates melbourne’s foundation). without exception all of these pictures conjure from this moment at the origins of melbourne an imaginary event—the ",
            "meeting of buckley and batman, in each image the centre of the picture’s narrative. (in fact, batman had already returned to van diemen’s land, and would meet buckley only later, upon his return to narrm, or port phillip BAY.) </p><p>in all of these images their meeting takes place at a campfire, but in other respects they vary wildly. some have buckley alone, imagined as an antipodean robinson crusoe, a european emerging ",
            "from 32 years in the wilderness (an idea also registered in buckley’s literary name, ‘wild white man’). others remain closer to the reality of the event—buckley emerging from the bush, accompanied by his wathaurung kin—while one image even has buckley seated serenely, god-like, being discovered by batman. </p><p>why does this imaginary encounter recur in images of this foundational moment of our city? ",
            "</p><p>this sculpture—a chimney designed to house an imagined fire—is dedicated to that moment, to that moment of an imagined meeting at a fireplace, and to the questions of these origins.",
        ],
        font: "TN Sleigh",
    },
    {
        title: "Monument to four words of silence",
        content: [
            "Look at this photograph. It is a gathering to unveil a new monument to John Batman, author of Batman’s Treaty, at the Old Melbourne Cemetery (now cleared) on 3 June 1882. A flag enfolds the monument, an obelisk that claims Batman as the founder of the settlement ‘on the site of Melbourne then unoccupied’. </p><p>Melbourne’s mayor, C. J. Ham, addresses the gathering. He dramatically produces Batman’s original diary for the crowd, reading ",
            "aloud a passage from the entry written on 8 June 1835, two days after the date of Batman’s Treaty: </p><p><br>‘The boat went up the large river I have spoken of, running from the east, and I am glad to state that for about six miles there is a river of good water, and very deep. This will be the position for a village.’</p><p><br>The Mayor makes much of the authority of the diary as he holds it in his hands, OF its importance as a",
            "relic of the city’s founding moment. But he does not read aloud the last four words from that diary entry: ‘the natives on shore’. </p><p>Stand within this narrow vertical structure. Enfolded within this brick monument, speak aloud those four words in order: ‘the’ ‘natives’ ‘on’ ‘shore’. Return those four words to us from the silence of this gathering. ",
            <>
                <img
                    src={image1}
                    width="462"
                    height="325"
                    class="artwork-image"
                    alt=""
                />
            </>,
        ],
        font: "TN Marshal",
    },
    {
        title: "Monument to a column of smoke",
        content: [
            "John Batman, author of Batman’s Treaty and often regarded as the founder of Melbourne, was born in Parramatta, Sydney. </p><p>As a young man he moved to Van Diemen’s Land, where he was involved in leading parties of European men who hunted down Aboriginal communities. </p><p>In 1829, Batman led a party that discovered an Aboriginal gathering by following a column of ",
            "smoke. Batman himself described what then took place at the mountain near his farm, Ben Lomond: </p><p><br/>‘… in pursuit of the Aborigines who have been committing so many outrages in this district on Wednesday, I fell in with their tracks and followed them with the assistance of the Sydney native blacks. We proceeded in the same direction until we saw some smoke at a distance. I immediately ordered the men to",
            "lay down. We could hear the natives conversing distinctly. We then crept into a thick scrub and remained there until after sunset. We made towards them with the greatest caution and at about 11 o’clock PM we arrived within 21 paces of them. The men were drawn up on the right by my orders intending to rush upon them. Unfortunately as the last man was coming up, he struck his musket against that of another party, which immediately alarmed the dogs (I number about 40). They came directly at us.",
            "The natives arose from the ground, and were in the act of running away into a thick scrub when I ordered the men to fire upon them which was done, and a rush by the party immediately followed. We only captured that night one woman and a male child about two years old. The next morning we found one man very badly wounded in the ankle and knee. Shortly after we found another. Ten buckshot had entered his body. He was alive but very bad. There ",
            "were a great number of traces of blood in various directions and we learnt from those we took that 10 men were wounded in the body, which they gave us to understand were dead or would die. We shot 21 dogs and obtained a great number of spears, waddies, blankets, rugs, knives, a tomahawk, a shingle wrench. On Friday morning we left the place for my farm with the two men, woman and child, but found it quite impossible that the two former could walk, and after trying them by every means in my power, for ",
            "some time, found I could not get them on. I was obliged therefore to shoot them.’</p><p><br>The two-year-old boy, Rolepana, who witnessed these two kin being executed, was adopted by Batman and later brought to Narrm/Melbourne, where he lived with Batman in his house on Batman’s Hill. </p><p>This tall brick monument attempts to encase an empty space—an empty space where we might imagine ",
            "that column of smoke at Ben Lomond—inside the form of the chimney Batman built to give warmth to his house in Melbourne, the first colonial dwelling to be constructed in the city. ",
        ],
        font: "TN Gaston",
    },
    {
        title: "Monument to a feoffment mistaken",
        content: [
            "‘There is not perhaps a more pleasing sight than when the blacks come into a Country in which they are perfect strangers, where none have before trodden, each comes with fire in their hand which is supposed to purify the air, they are ushered in by some intermediate medium Tribe whose ",
            "Embassadors have previously beem despatched, AND they are made welcome; from the forest which surrounds the new comers are cut boughs from each different tree … Each family has a separate seat raised from some dead log at hand … on which at one end sits the male head AND his male children AND at the other end, the female head AND her female children … ",
            "2 fires are made for the males, AND the other for the females. On the 1st day they are attended by those whose country they are invited. not allowed to do anything for themselves, water is brought them which is carefully stirred by the attendant with a reed AND the attendant takes a good draught to show that it is pure AND not injurious … In this ceremony males attend ",
            "males and females … during this ceremony the greatest silence prevails both by attendant AND attended, you may occasionally perceive in the aged tears of gratitude steal down their murky cheeks. At night miams are made for them. The meaning of all this is a hearty cordial welcome, as the boughs on which they have set are from various trees, as they ",
            "are at liberty to get from them ever after what they please, the water stirred with a reed is that no weapon will be allowed to touch them.’ </p><p><br> (William Thomas, Mitchell Library. MS214, Box 3 item 1/Reel 3 frame 58.) </p><p><br>This monument invites you to misunderstand Batman’s Treaty.",
            "</p><p>Batman’s Treaty is the name given to the feoffment—a type of deed of medieval European origins—that the entrepreneur John Batman claimed he signed with Wurundjeri elders on 6 June 1835. </p><p>The deed describes an exchange of 240,000 hectares of Wurundjeri land for blankets, knives, ",
            "looking-glasses, tomahawks, beads, scissors, flour and an annual tribute. It is not clear if any encounter between Batman’s party and Wurundjeri elders actually occurred on that date, nor, if indeed there was an encounter, what level of mutual comprehension was achieved. ",
            "</p><p>It is clear that the Treaty’s premise of private land ownership was outside the values of Wurundjeri culture. Even if it had been possible to explain this alien premise across language barriers, Wurundjeri elders would not have agreed. </p><p>It is possible that an encounter did take place on that day in ",
            "which the ritual of the feoffment (which involved handing over the branch of a tree or a handful of soil) was misunderstood by Wurundjeri elders as a tandarrum, a Wurundjeri ritual of symbolic exchange granting temporary use of resources, not dissimilar in form to the ritualized exchange of a feoffment. ",
            "</p><p>This monument, a free-standing chimney, is dedicated to extending that misunderstanding into the present. Can we retrospectively apply the mistaken version of Batman’s Treaty, and assimilate ourselves to that tandarrum of 6 June 1835? Can we re-inhabit this city through new protocols and rituals of sharing according to ",
            "need? Can we bring to these streets our shared civic conduct, the same conviviality we enact at home, before the hearths in our own living rooms? ",
        ],
        font: "TN Regiment",
    },
    {
        title: "Monument to a Pitcairn farm escape",
        content: [
            "John Batman, often known as the founder of Melbourne, claimed he co-signed a deed with Wurundjeri elders on 6 June 1835, a document best known as Batman’s Treaty. The MARKS of the Wurundjeri elders were forged by Batman, and a second deed, known as the Geelong Deed, was fabricated after Batman returned to Van Diemen’s Land. ",
            "</p><p>Before travelling to Narrm, or Port Phillip, with his Treaty, Batman had been a prominent grazier living in Van Diemen’sLand and HAD led parties of British men who hunted down Aboriginal people to capture or kill them. </p><p>The first time Batman led such a party he famously captured an adolescent boy who HE imprisoned in a farmhouse at Pitcairn. The boy escaped his British captors ",
            "during the night by climbing up the inside of the farmhouse chimney.",
        ],
        font: "TN Gaston",
    },
    {
        title: "Monument to what William Buckley left",
        content: [
            "This free-standing chimney remembers the life of William Buckley. Born near Macclesfield, Cheshire, in 1780, Buckley served in the British army, fighting against Napoleon,s troops in the Netherlands in 1799. In August 1802, he was convicted of receiving cloth knowing it to have been stolen, and was sentenced ",
            "to transportation for life. After his escape from the short-lived penal colony on Boonwurrung country, at Sullivan Bay (today,s Sorrento), Buckley made his way around Narrm, or Port Phillip Bay, walking alone for many days. On the Bellarine Peninsula, Wathaurung land, around present-day Geelong, Buckley was met by a group of Wathaurung men and women, who received him into their community. ",
            "Buckley lived with Wathaurung people for the following 32 years. </p><p>In 1835, John Batman, a grazier living in Van Diemen’s Land, travelled to Narrm, the future site of Melbourne, as leader of the entrepreneurial Port Phillip Association and claimed to have signed a Treaty with Wurundjeri elders. Buckley heard about Batman’s arrival ",
            "in Narrm and walked with Pulmadaring and several other Wathaurung kin to Batman’s camp at Indented Head. Recognising Buckley as someone who could serve as a valuable intermediary with Aboriginal people, Batman successfully sought his pardon from Lieutenant-Governor George Arthur in Hobart and then employed Buckley as the Port Phillip ",
            "Association's ‘Superintendent of Native Tribes’. </p><p>The tensions of Buckley's position quickly became apparent. They are most evident in the diary of Joseph Tice Gellibrand, another leading member of the Port Phillip Association, who in February 1836 travelled with Buckley back to Wathaurung country, to the Bellarine ",
            "Peninsula. In an entry DATED 5 February 1836, Gellibrand writes: </p><p><br>‘I directed Buckley to advance and we would follow him at a distance of a quarter of a mile. Buckley made towards a native well and after he had rode about 8 miles, we heard a cooey and when we arrived at the spot I witnessed one of the most pleasing and ",
            "affecting sights. There were three men, five women and about twelve children. Buckley had dismounted and they were all clinging around him and tears of joy and delight were running down their cheeks. It was truly an affecting sight and proved the affection which these people entertained for Buckley … Amongst the number were a little old man and an old woman, one of his wives. ",
            "Buckley told me this was his old friend with whom he had lived and associated thirty years.' </p><p><br>Later, on 13 February 1836, Gellibrand writes:<br></p><p>‘Upon my arrival at the Settlement I found about one hundred and fifty natives and I learnt ",
            "with much concern that an act of aggression had been committed upon one of the women which required my immediate attention … This woman was proceeding towards the settlement to see her mother and fell in with one of the shepherds who laid hold of her, brought her to the hut, tied her hands behind her, and kept her there all night, and either that night or next ",
            "morning abused her person … She communicated to her friends the injury she had sustained and they immediately apprized Buckley of it to obtain redress.'</p><p><br>Gellibrand's accounts are fragments of invasion and its crimes. They also evoke Buckley's situation, both as a man living within Wathaurung society AND as a ",
            "British man involved in the invasion of Port Phillip, an enterprise THAT rapidly produced acts of violence and dispossession towards Aboriginal communities, ACTS for which British settlers largely enjoyed immunity. </p><p>Buckley left Port Phillip in December 1837. He settled in Hobart, Van Diemen,s Land, where he remarried, and ultimately died on 30 ",
            "January 1856. He left behind in Melbourne a single built structure: the city,s first colonial chimney, which he built for Batman,s house, the city,s first permanent colonial dwelling, using his skills as a bricklayer, learnt as a young man in England before his transportation. ",
        ],
        font: "Caslon Ionic",
    },
    {
        title: "Unfinished monument to Batman’s Treaty",
        content: [
            "This vertical brick structure presents an invitation to you, your family, your friends. Please attach this plaque to the chimney in your living room. ",
            <>
                <img
                    src={image2}
                    width="567"
                    height="361"
                    class="artwork-image"
                    alt=""
                />
            </>,
            "An A4 pdf of the text can be printed out FROM www.unfinishedmonumentto batmanstreaty.org, and framed in any standard A4 picture frame. With this gradual process of re-inscription, our chimneys are transformed into a monument to Melbourne,s beginnings, as vast as the city itself. ",
        ],
        font: "Copernicus",
    },
    {
        title: "Monument to a depression in the landscape",
        content: [
            "On 6 June 1835 John Batman claims he signs a Treaty with Wurundjeri elders on the banks of the Merri Creek, a tributary of the Birrarung, the Yarra River. </p><p>The Treaty is regarded as void by the Crown, but precipitates a rapid and violent process of ",
            "invasion as facts on the ground: the establishment of the city of Melbourne. </p><p>Along with other displaced Aboriginal people, the Wurundjeri people who survive invasion ultimately settle at Coranderrk, where they establish a thriving community on the banks of the Birrarung near present-day Healesville. ",
            "</p><p>It is a base for political self-organisation, petitioning the Crown for land and rights, and for economic self-reliance including farming (most famously award-winning hops) and brickmaking (extracting clay from a pit near the Coranderrk cricket ground and firing the bricks in an adjacent kiln). ",
            "</p><p>The growing militancy of Aboriginal self-organisation at Coranderrk leads to the implementation of the ‘Aborigines Protection Act 1886’, designed to dismantle communities such as Coranderrk, and to dissolve ALL Aboriginal commu-nities into the dominant settler society, to achieve, ",
            "as colonial administrators describe it, ‘finality’. </p><p>In 1924 Coran- derrk’s remaining residents are forced to move to Lake Tyers Mission in East Gippsland, their houses destroyed. Today, only one building remains from the original Coranderrk village, the station manager’s house. Nearby, a depression in the landscape ",
            "is the trace of Coranderrk’s brickmaking industry, the remnant of the clay pit for the bricks that can still be found in buildings in and around Healesville, and dotted through the landscape around Coranderrk. </p><p>This monument imagines a chimney made from Coranderrk bricks, a vertical form for our ",
            "streets as a counterpart to that gently-curved void in the landscape at Coranderrk. ",
        ],
        font: "TN Regiment",
    },
    {
        title: "Monument for the image of a Melbourne exile",
        content: [
            "The Italian poet Dante Alighieri, in exile from his beloved Florence, writes an open letter to the Italian people in 1310, describing the dawn sky, an image of solace for the exile, the universality of its promise reaching beyond the exile’s bounded movements, suggesting a broader citizenship which cannot be revoked: ",
            "‘Now is the favourable time, when signs of solace and of peace are emerging. For a new day is breaking and the light of that dawn is now allaying the shadows of our long period of adversity. Now the East winds are increasing. The sky is glowing red to its farthest reaches and its gentle calm is inspiring hope in all people. And we too, who for so long have endured the dark night of the wilderness, shall see our long-awaited source ",
            "of joy, when Titan will rise in peace, and when Justice—which has become dim, like the heliotrope, in the sun’s absence— will be revived by the touch of the first light of day. All those who hunger and thirst for justice will be satisfied in the light of the radiance, while those who love injustice will be confounded by his dazzling face. For the great Lion of the tribe of Judah has pricked up his merciful ears, and, taking pity on the lamentations of our universal captivity, ",
            "he has called up a new Moses, who will deliver his people from their Egyptian oppression and lead them to a land flowing with milk and honey.’</p><p>Enter this vertical brick structure, a space for this image of the sky, dedicated to the exile, the exile we begin with Batman’s Treaty, and the invasion of this place. Enter this monument and look up, look for this image of the sky.",
        ],
        font: "Optima",
    },
    {
        title: "Monument to a brickmaker",
        content: [
            "Two European figures, John Batman and George Augustus Robinson, heavily influenced the relations between Aboriginal and European communities in early colonial Tasmania (then known as Van Diemen’s Land). </p><p>Batman was a grazier involved in parties of men who hunted down and killed Aboriginal men, women and children. ",
            "</p><p>Batman later became famous as the founder of the city of Melbourne when he crossed Bass Strait as leader of the entrepreneurial Port Phillip Association and claimed he signed a deed with Wurundjeri elders, now best known as Batman’s Treaty. </p><p>The Treaty was never accepted by the Crown but precipitated de facto a tumult of immigration, dispossession and violence, largely by other settlers from Tasmania who brought ",
            "with them their experiences from across Bass Strait. </p><p>Actively involved in both histories of genocide, Batman also built Melbourne’s first European dwelling, its chimney constructed by William Buckley, the escaped convict who lived with Wathaurung people around present-day Geelong for 32 years. </p><p>Robinson, originally a brickmaker by trade, became famous in the new colony of Tasmania for his more ",
            "diplomatic attitude towards Aboriginal people (and also for his deep antipathy towards Batman). </p><p>Though sometimes celebrated as a peacemaker, Robinson’s extensive dealings with Aboriginal people throughout Tasmania were generally mendacious and instrumental in the attempt to purge the island of the people who had occupied those lands for many thousands OF years, concentrating them on Flinders Island, the largest of the ",
            "islands that lie between Tasmania and Victoria in Bass Strait. </p><p>This tall brick monument commemorates the complicated relations between Robinson and Batman—and between the histories of Tasmania and Victoria—imagining an obelisk to Robinson’s crimes through his original profession as a brickmaker, in turn an echo of Melbourne’s first European chimney, the hearth that warmed Batman’s living room on the other side of Bass Strait.",
        ],
        font: "TN Sleigh",
    },
    {
        title: "Monument for the word ‘bread’ spoken aloud",
        content: [
            "Murrangurk, better known as William Buckley, was convicted of receiving cloth knowing it to have been stolen. After escaping from the fledgling penal colony on Boonwurrung country, at Sullivan Bay (present-day Sorrento) in 1803, Buckley met a group of Wathaurung men and women, who received him into their community, granting him a kind of asylum. Buckley lived with Wathaurung people for the following 32 years. He later wrote that he was given the name ",
            "Murrangurk, ‘which I afterwards learnt was the name of a man formerly belonging to their tribe, who had been buried at the spot where I had found the piece of spear I still carried with me.’ </p><p>The act of naming became important at a second transforming moment of Buckley’s life 32 years later, when HE arrived at John Batman’s camp at Indented Head, shortly after Batman claimed to have signed his Treaty with Wurundjeri elders near the future site of Melbourne, on 6 June 1835. Buckley was initially unable to ",
            "communicate with the men at Batman’s camp. His English had deserted him. One of Batman’s men offered Buckley a piece of bread, which he began to eat. As he sat at Batman’s campfire, the taste of the bread prompted the memory of his mother tongue, and Buckley enunciated the word ‘bread’. Later, using his skill as a bricklayer, Buckley built the chimney for Batman’s house, Melbourne’s first permanent European dwelling. </p><p>This monument imagines —through Buckley’s bricklaying—the campfire at Indented Head where Buckley tasted",
            "that bread, cooked on that same fire, and spoke aloud its name. </p><p>Stand inside this free-standing chimney and speak aloud the word ‘bread’. Speak aloud the word ‘bread’, remembering that moment at John Batman’s campfire and the weight of that single word. ",
        ],
        font: "TN Circumspice",
    },
    {
        title: "Monument to Coranderrk",
        content: [
            "This monument remembers Coranderrk. This monument remembers the long march from Acheron in 1862, the men, women and children walking to this home by the banks of the Birrarung, the Yarra River, ",
            "a home for the Wurundjeri, displaced by the invasion that begins in 1835 with Batman’s Treaty, and a home for Aboriginal people from other parts of Victoria and beyond. This monument remembers the beauty of Coranderrk, the bounty of that home, and the continuing force ",
            "of what is created at Coranderrk. This monument remembers the destruction of Coranderrk in 1924, its residents forced to Lake Tyers Mission in East Gippsland, five old people who would not leave, the houses cleared away, the bricks from Coranderrk ",
            "used to supplement building materials for the expansion of Healesville. This monument is created from used bricks collected from the citizens of Healesville in 2013, for an obelisk that is also a chimney, the reminder of a home.",
        ],
        font: "Caslon Doric",
    },
    {
        title: "Monument to courses upon a living mound",
        content: [
            "For thousands of years, a beautiful low-rising hill overlooked the wetlands around the junction of the rivers we know as the Yarra River (Birrarung to the people of the Kulin Nation) and the Maribyrnong River. </p><p>It was notable for its tree cover, mainly She-oaks, and it was a place from which the river system, its wetlands and its abundant resources could be viewed and ",
            "utilised, as well as being close to the falls which separated the tidal and freshwater parts of the Birrarung. </p><p>The hill was a geological feature, but its height and shape were supplemented by layers of structures built by Kulin people over many centuries, including semi-permanent dwellings, tools, ovens and clay balls (fired clay used for cooking). IT WAS OFTEN DESCRIBED AS A ‘LIVING MOUND’. </p><p>When Europeans ",
            "arrived in 1835 this Hill was named Pleasant Hill. Later it was given the name Batman’s Hill, after John Batman, a grazier living in Van Diemen’s Land who travelled to Narrm, Port Phillip Bay, the future site of Melbourne, as leader of the entrepreneurial Port Phillip Association and claimed to have signed a Treaty with Wurundjeri elders. Batman’s Treaty was never accepted by the Crown, nor does it hold any validity for Wurundjeri people today. But the words of Batman’s ",
            "Treaty triggered de facto the invasion of what we now call Victoria. </p><p>Batman ultimately purchased more modest holdings from the Crown and built Melbourne’s first permanent European dwelling on Batman’s Hill. </p><p>The chimney for this house was built by William Buckley, the escaped convict (originally trained as a bricklayer) who had lived with Wathaurung people for more than 30 years near present-day Geelong. ",
            "</p><p>Today, Batman’s Hill is absent from Melbourne’s landscape. it was cleared during the nineteenth century to make way for what is today Southern Cross station. </p><p>These courses of bricks—layers and layers to create this tall vertical form—are poised between a free-standing chimney and an obelisk. They figure a monument to dwell upon that hearth built by Buckley for Batman, on Batman’s Hill, and to pose this ",
            "question: how would we pull this down and re-assemble these bricks, their courses, to begin to imagine the layers of that living mound, their claims upon our present? ",
        ],
        font: "TN Marshal",
    },
    {
        title: "Monument for another column of smoke, for other flags",
        content: [
            "This chimney is a monument to house another column of smoke and other flags we might imagine to mark 2pm Sunday 6 July 1835, ",
            "when William Buckley, escaped convict LIVING for 32 years with the Wathaurung, following a column of smoke and a flagpole flying the Union Jack, arrived at Indented Head, Wathaurung country, accompanied by his ",
            "Wathaurung kin at the campfire of John Batman, author of Batman’s Treaty. This chimney is for that moment, to enact new meetings around a column of smoke, and to picture new flags. ",
        ],
        font: "TN Grimwade",
    },
    {
        title: "Monument to scales and earth",
        content: [
            " This brick monument begins with matter taken from the earth. </p><p>The clay bricks for narrm /Melbourne’s first colonial chimney are laid by William Buckley. Originally convicted for possessing cloth knowing it to have been stolen, Buckley escapes from the fledgling penal colony on the country of the Boonwurrung, of the Kulin Nation, at Sorrento. </p><p>He walks for many days, and is ultimately received by the Wathaurung, of the Kulin Nation, and lives ",
            "with Wathaurung people for 32 years before re-joining colonial society and working to facilitate the process of invasion. </p><p>It is during this period that Buckley builds Narrm/Melbourne’s first colonial chimney, on Batman’s Hill, for John Batman, the grazier who in 1835 travels from Van Diemen’s Land to claim he signs a deed with leaders of the Wurundjeri, of the Kulin Nation, a deed best known as Batman’s Treaty. Wurundjeri Ngurungaeta (or headman) William Barak, or Beruk, witnesses this encounter as a boy. </p><p>Batman’s Treaty is never accepted by the Crown, nor does it hold ",
            "any validity for Wurundjeri people today. But the words of Batman’s Treaty precipitate de facto the invasion of Narrm, and then the territories we now know as Victoria, one of the most rapid and violent processes of invasion in the history of the British Empire. The clay bricks of Batman’s chimney closely follow the claims of his Treaty, its consequences for dispossession. </p><p>At the same time that this conflicted colonial chimney stands on Batman’s Hill, Beruk witnesses another encounter, between Wathaurung and Wurundjeri. This encounter is at the greenstone quarry at Mt William, a critical ",
            "site for tools and trade for people of the Kulin Nation. Many years later the anthropologist and naturalist Alfred William Howitt recalls: </p><p><br> ‘As a good instance of the manner in which trespasses by a person of one tribe on the country of another tribe were dealt with, I take the case of a man of the Wudthaurung tribe, who unlawfully took, in fact stole, stone from the tribal quarry at Mt. William near Lancefield. I give it in almost the exact words used by Berak in telling me of it, and who was present at the meeting which took place in consequence, probably in the ",
            "late forties. It having been found out that this man had taken stone without permission, the Ngurungaeta Billi-billeri sent a messenger to the Wudthaurung, and in consequence they came as far as the Werribee River, their boundary, where Billi-billeri and his people met them. These were the men who had a right to the quarry, and whose rights had been infringed. The place of meeting was a little apart from the respective camps of the Wurunjerri and the Wudthaurung. At the meeting the Wudthaurung sat in one place, and the Wurunjerri in another, but within speaking distance. The old men of each side sat together, with the younger men behind them. Billi-billeri had behind him Bungerim, to whom ",
            "he “gave his word”. The latter then standing up said, “Did some of you send this young man to take tomahawk stone?” The Headman of the Wudthaurung replied, “No, we sent no one.” Then Billi-billeri said to Bungerim, “Say to the old men that they must tell that young man not to do so any more. When the people speak of wanting stone, the old men must send us notice.” Bungerim repeated this in a loud tone, and the old men of the Wudthaurung replied, “That is all right, we will do so.” Then they spoke strongly to the young man who had stolen the stone, and both parties were again friendly with each other. At such a meeting all the weapons were left at the respective camps, and each speaker stood up in addressing it.’ ",
            "</p><p>This monument is a tall vertical brick structure, and a greenstone footing. It recalls these two encounters on Kulin country, witnessed by Beruk, and these two moments of a legality manifested through matter taken from the earth. This monument draws into relation these two moments. It is a prompt to re-think this place, the scales of its histories, towards a principle of justice. It is a monument to begin to weigh up the matter of legalities. ",
        ],
        font: "TN Gaston",
    },
    {
        title: "Monument for a dissassembling",
        content: [
            "In 1835 John Batman travels across Bass Strait from Van Diemen’s Land to Narrm, where he claims he signs a Treaty with Wurundjeri people, the document that precipitates the invasion of what comes to be known as Victoria and the establishment of the city of Melbourne. ",
            "</p><p>During Batman’s travels through Victoria he repeatedly admires Aboriginal aquaculture, and notes the association of these fish traps with permanent Aboriginal housing. Writer and historian Bruce Pascoe writes extensively about other Europeans in the early history of the invasion who also record the variety and ingenuity of Aboriginal ",
            "permanent housing, other forms of architecture and townships, including Charles Sturt, Thomas Mitchell and John McDouall Stuart. </p><p>George Augustus Robinson’s drawing of Caramut, an Aboriginal village in Western Victoria, around 1840, shows a dense cluster of domed houses with an opening in the roof for smoke to escape. ",
            "</p><p>In 1836, Batman builds Melbourne’s earliest permanent colonial dwelling, an act of home-making that closely follows the claims of his Treaty. The chimney for Batman’s house is built by William Buckley, the escaped convict who lives with Wathaurung people for 32 years, originally trained as a bricklayer in Cheshire, England, before ",
            "his conviction for possessing cloth knowing it to have been stolen. </p><p>In 1934, ‘the oldest building in Australia’ is constructed in Melbourne. Sir Russell Grimwade buys Captain Cook’s Cottage, a house originally built in 1755 in Yorkshire, England. The house is disassembled, each brick is individually numbered, packed into 253 cases and 40 ",
            "barrels, transported to Melbourne, then reconstructed in the Fitzroy Gardens. A flagpole is erected in front of the Cottage, where the Union Jack still flies today. Grimwade donates Captain Cook’s Cottage to the people of Victoria, as part of the 1935 centenary of Batman’s arrival and the establishment of the city of Melbourne. ",
            "</p><p>This tall vertical brickstructure is a 1:1 scale model for a process of disassembling. Captain Cook’s Cottage is taken apart, brick by brick. These bricks are then used, one by one, in numerical order, to build a tall vertical structure to entomb the flagpole, a structure to still the flying of that flag, a brick trap for cloth. The Cottage becomes ",
            "an echo of Melbourne’s earliest colonial chimney, built by Buckley for Batman. It encases the Cottage’s Union Jack in its own matter. It is a monument to disassemble how we think about architecture in this place, and to begin the order of that thinking again. ",
        ],
        font: "TN Circumspice",
    },
    {
        title: "Monument to Coranderrk hearths",
        content: [
            "This monument remembers Louisa Briggs, a bagurrk (woman) of the Boonwurrung of the Kulin Nation, born around Nairm in the 1830s, at the time of Batman’s Treaty. </p><p>Briggs is kidnapped by sealers around Monmaa (Point Nepean) and taken to the islands in Bass Strait where she works for sealers and marries John Briggs, a descendant of palawa (Tasmanian Aboriginal) survivors of the invasion of Van Diemen’s Land. ",
            "</p><p>Louisa Briggs returns to her Kulin country and rejoins her kin at Coranderrk, on the country of the Wurundjeri, of the Kulin Nation, where a flourishing Aboriginal community is established in 1863. Briggs is actively involved in the struggle for rights and is the midwife and carer for many of the bubup (children) at Coranderrk. </p><p>In 1876 MELBOURNE NEWSPAPER ‘THE ARGUS’ reports: ‘She is matron of the establishment, on a salary of ten shillings ",
            "a week, and manages the affairs of the children and young people “in school” with the utmost vigilance and much success. She is their cook and laundress and general monitor and gouvernante [governess]. She is also the accoucheuse [midwife] in ordinary of the establishment, the general nurse in sickness, and a handy and vigorous all round administrator. Coranderrk would not be what it is without Mrs Briggs.’</p><p>(‘The Coranderrk Aboriginal station’, (‘The Argus’, Friday 1 September 1876.) ",
            "</p><p>When the victorian government forcibly removes the family from Coranderrk, Briggs moves with her family to the Maloga Mission on the Murray River and later on to Cummeragunga, which in turn becomes a critical site for Aboriginal self-organisation in the twentieth century. </p><p>This monument is a free-standing brick chimney, that strange part of a building that warms the very inside of a home but is also visible from outside, from afar, a portal at the rooftop to the sky outside. ",
            "It commemorates the life of Louisa Briggs through the chimneys at Coranderrk, always a notable feature in photographs of the houses that line Coranderrk’s main road. This brick monument imagines the view of those chimneys from inside. It begins with the hours upon hours of work, knowledge and love Briggs undertook before the hearths of Coranderrk in those years, and with the weight of those hours upon the present, upon our own living in this place. ",
        ],
        font: "Plantagenet Cherokee",
    },
    {
        title: "Monument to an engraving of removal",
        content: [
            "Look at this engraving. </p><p>An engraving is made by cutting lines into a surface, an action reprised in this scene: ‘Removing the last of Batman’s Hill’. The hill that comes to be known as Batman’s Hill overlooks the remarkable wetlands system near the mouth of the Birrarung, or Yarra River. </p><p>In the mid-1830s it is named for John Batman, best known as the author of Batman’s Treaty, the falsified document Batman claims he signs with Wurundjeri elders in 1835. ",
            "</p><p>Batman builds Melbourne’s first permanent European dwelling on this hill, its chimney built by William Buckley, the escaped convict who liveD with Wathaurung people for 32 years, originally trained as a bricklayer. </p><p>By the time this engraving is made, in 1892, most of the hill is cleared, including Batman’s house, and the hill is surrounded by polluted waterways, industrial canals, slaughterhouses, boiling-down premises and a powder magazine. The last of Batman’s Hill is being cleared to make way for train lines. </p><p><br>Look at these figures. ",
            "</p><p>The figure at the right of the image is also looking. He regards the line of workers removing the last of Batman’s Hill, looking back down the railway line where he stands at the end. </p><p>The strange resemblance between his own appearance and the appearance of the line of workers suggests he is regarding a sequence of his own past actions, Muybridge-like, a sequence that unfolds backwards down the line of the train tracks. </p><p><br>Look at this monument.</p><p>This monument re-figures this engraving, through that chimney built on Batman’s Hill, ",
            "Melbourne’s first European hearth, built by Buckley for Batman. It is a tall vertical brick structure to reprise this column of men and their digging, imagining the earth they are removing as a concentration, into the density of bricks. The engraving and its horizontal line of bodies—a perspective system of bodies without a vanishing point—becomes this vertical structure, a brick column reaching endlessly upwards towards the sky. It is a monument to the removal of this Hill, through the image of a brick chimney built upon it. ",
            <>
                <img
                    src={image3}
                    class="artwork-image"
                    height="330"
                    width="499"
                    alt=""
                />
            </>,
        ],
        font: "Field Grot",
    },
    {
        title: "Monument to Buckley’s complications",
        content: [
            "This monument, this vertical brick structure before you, is dedicated to the uncertain status of William Buckley. A British convict, originally trained as a bricklayer, transported to Australia for possessing cloth knowing it to be stolen, Buckley escapes from a short-lived penal colony on Boonwurrung country, ",
            "at Sorrento, in 1803. He lives with Wathaurung people around the Bellarine Peninsula for over 30 years. </p><p>In July 1835 he hears about the arrival of the grazier John Batman—perhaps best known as the author of Batman’s Treaty—and walks with Pulmadaring and several other Wathaurung men and women to Batman’s camp at Indented Head. Buckley then rejoins British society, ",
            "and is briefly employed by Batman as an intermediary with Aboriginal people during the first months of invasion. </p><p>Perhaps the most vivid surviving image of Buckley is produced in 1890, many years after his death, by the Kwatkwat artist Tommy McRae, also recorded as Yackaduna or Warraeuea. Though McRae’s long life is deeply marred by the effects of invasion (his children are ",
            "forcibly removed by the Crown under the ‘Aborigines Protection Act 1886’, the original ‘stolen generations’ legislation and the model for the many Acts that follow in different parts of Australia), McRae’s drawing seems to remember Buckley affectionately. It shows Buckley dancing perfectly in unison with a group of Wathaurung men, onlyhis white hairy skin and top hat distinguishing him from his ",
            "fellow dancers. McRae usually shows Buckley smoking a pipe, and here one of his friends holds that pipe playfully aloft towards the sky. McRae also places a British ship on the horizon, looming over the scene of dancing, a row of flags atop the ship’s masts a presence that laces the joyfulness of the dancing with the foreboding of an invasion, the arrival of a new sovereign order. ",
            "</p><p>This monument remembers the complications of Buckley, focusing on the pipe McRae gives to Buckley, making a simple brick structure reaching towards the sky, to be filled by the smoking of a fire. ",
        ],
        font: "TN Councillor",
    },
    {
        title: "Monument to the shaping of words",
        content: [
            "Can we say that the invasion of Narrm—the establishment of the city of Melbourne—begins with words? John Batman holds that he co-signs a deed with Wurundjeri elders, a confected document best known as Batman’s Treaty, claiming 240,000 hectares of Wurundjeri land in exchange for trinkets and an annual tribute. </p><p>Batman’s Treaty is never accepted by the Crown, nor does it hold any validity for Wurundjeri people today. But the words of Batman’s Treaty ",
            "precipitate de facto the invasion of what we now call Victoria, one of the most rapid and violent processes of dispossession in the history of the British Empire. </p><p>This monument also begins with words, the words of monuments themselves. During 2018, designers Vincent Chan and Ziga Testen lead a census of the typographies of Narrm/Melbourne’s monuments, forms populating—but also claiming—that same landscape as the words of Batman’s Treaty. These vernacular forms are recorded and re-drawn by Chan and Testen, with assistance from Kathryn ",
            "Fridman and Dennis Grauel, as the invention of new typefaces. </p><p>This monument—a simple vertical brick structure, the most rudimentary form of an obelisk—is to house words, a multiplicity of plaque texts, each rendered in a typeface created through the census, each a counter-text to a plaque that exists somewhere in this city. It is an obelisk that works towards typographically gathering together the entire city’s monumental complex, a landscape of words that is also an archaeology of writing. It is a work to ",
            "shadow the claims of words dispersed across this city: </p><p>a monument to the shaping of words. ",
        ],
        font: "TN Gaston",
    },
    {
        title: "Monument to breaking bread",
        content: [
            "In 1940 Frank Heritage builds the Heritage Mortuary and Funeral Parlour on the Maroondah Highway, a building that still stands in Healesville today. </p><p>He uses bricks acquired from the Coranderrk Aboriginal Station, a thriving community established near Healesville in 1863 by Wurundjeri and other Aboriginal people displaced by the ",
            "establishment of Melbourne in 1835, an event precipitated by Batman’s Treaty and its ramifications. </p><p>The bricks Heritage acquires in 1940 are from the building that once houses the Coranderrk bakery. He employs local children in Healesville to clean these 80,000 bricks, each created at Coranderrk with clay extracted from the Coranderrk clay pit, and moUlded and fired on site. The collective act of cleaning ",
            "these bricks is accompanied by eating; Heritage remunerates the children’s brick-cleaning work with lollies. </p><p>This monument imagines re-using these bricks once more to create this tall vertical form: in part brick obelisk, in part free-standing chimney, in part oven. It is a monument to the life of these bricks. It is a monument to return these bricks to the act of baking, to the act of breaking bread.",
        ],
        font: "Helvetica, Arial",
    },
    {
        title: "Monument to a public gathering",
        content: [
            "on 20 january 1842, over 5,000 people, one quarter of victoria’s non-aboriginal population, gather around a tall vertical structure, narrm/melbourne’s first gallows. </p><p>this gathering is the conclusion of a story that begins with george augustus robinson, originally a brickmaker by trade, famous in tasmania for his central role in removing tasmania’s aboriginal population to flinders island. ",
            "</p><p>one of several leading figures with a deep antipathy towards john batman, robinson comes to narrm/melbourne to become the chief protector of aborigines in 1839, just four years after batman’s treaty. he brings with him 17 tasmanian aboriginal men and women to ‘civilise’ the victorian ‘blacks’. in 1841, one of these men, known to robinson as napoleon but better known as tunnerminnerwait (and also known as pevay and jack of cape grim), spends six months travelling with robinson in ",
            "the western district of victoria, witnessing the extreme violence that accompanies the land grab in that part of victoria, precipitated by the implications of bourke’s proclamation, in turn a response to the ramifications of batman’s treaty. </p><p>upon his return from the western district, in september 1841, tunnerminnerwait, his wife planobeena and three of his kin, pyterruner, maulboyheenner and truganini, escape from robinson, stealing guns and ammunition from a ",
            "settler’s hut at bass river. at large for six weeks, their exploits mirror the aboriginal guerilla warfare occurring throughout victoria during these years, and earlier in tasmania during the ‘black war’. a party of police, settlers and soldiers capture the five tasmanians, who are charged with murdering two bass strait whalers. redmond barry, the standing defence counsel for aborigines, argues that as the five defendants are not naturalised citizens, half the jury ",
            "should be made up of people not subjects of the queen. barry continues to question the legal basis of british authority over aborigines. </p><p>through robinson’s lobbying, truganini is spared, along with her two female kin, and ultimately returns to flinders island, then oyster cove, where she dies in 1876. </p><p>tunnerminnerwait and maulboyheenner are sentenced to hang, and on 20 january 1842, in front of a gathering of 5,000 people, ",
            "become the first people executed in the fledgling colony of victoria. they are buried in the old melbourne cemetery, now occupied by the queen victoria market. </p><p>this structure commemorates these events in melbourne’s early history. an obelisk, a tall vertical structure constructed through robinson’s first profession, his brickmaking, it alludes to those first gallows. it is a structure for us to stand within, a tall slither of dark space to inhabit, where we might ",
            "imagine the histories witnessed by tunnerminnerwait and maulboyheenner, on both sides of bass strait. ",
        ],
        font: "TN Sleigh",
    },
    {
        title: "Monument from a public call-out for bricks",
        content: [
            "This simple vertical brick form is for the art project ‘untitled (seven monuments)’, created in and around Healesville by Aunty Joy Murphy Wandin, Jonathan Jones and Tom Nicholson, realised between 2013 and 2019. </p><p>Seven brick markers ARE dotted along the original boundary of Coranderrk Aboriginal station, where Wurundjeri and other displaced Aboriginal people establish a remarkable community in ",
            "1863. Coranderrk fights for its rights and maintains culture. It is self-sufficient; its industries include farming and brickmaking. </p><p>Each of these seven BRICK markers is the footing of an upturned flagpole, concealed within country. Coranderrk (Christmas bush) slowly grows upwards. </p><p>This tall obelisk is created with bricks from a public call-out, collected from citizens in and around Healesville, from the country of Coranderrk. ",
            "It is a marker for the art project ‘untitled (seven monuments)’, a marker located here in Melbourne, the city triggered by Batman’s Treaty, the falsified document John Batman holds that he signs with Wurundjeri people in 1835. It re-imagines those seven brick footings for upturned flagpoles through the image of a tree, as a brick structure growing upwards, a reminder of the country at Coranderrk, reaching towards the sky amidst the vertical structures of this city. ",
        ],
        font: "TN Marshal",
    },
    {
        title: "Doubled monument",
        content: [
            "In 1835 John Batman travels across Bass Strait to Narrm, to the country of the Kulin Nation, as leader of the entrepreneurial Port Phillip Association. He claims he signs a treaty with the Wurundjeri people of the Kulin Nation on 6 June 1835, exchanging 240,000 hectares ",
            "of Wurundjeri land for trinkets and an annual tribute, a treaty best known as Batman’s Treaty. </p><p>Melbourne’s first permanent European dwelling is built by Batman on what comes to be known as Batman’s Hill (now cleared). The chimney is built by William Buckley, the escaped convict hosted for 32 years within ",
            "Wathaurung society, around the Bellarine Peninsula. </p><p>Batman dies in 1839, and is buried in the Old Melbourne Cemetery (now cleared). A monument celebrating Batman as the founder of the settlement ‘on the site of Melbourne then unoccupied’, erected in the cemetery in 1882, is left standing at the site of his ",
            "original burial when the Old Melbourne Cemetery is moved. His remains are extracted from the ground and relocated to a new cemetery in the suburb of Fawkner, where they are united with a replica of the same monument, its double. </p><p>This monument here is also a double, a double of the chimney that warmed ",
            "the city’s first permanent European dwelling, built for Batman by Buckley. It is a hearth conceived as an obelisk, a doubled monument: against the claims of the Treaty, against the claims of that home-making. ",
        ],
        font: "TN Crawford",
    },
    {
        title: "Monument to the image of fire",
        content: [
            " As a young Wurundjeri boy Barak witnesses a meeting between John Batman and Wurundjeri elders. At this meeting, on 6 June 1835, Batman claims he co-signs with Wurundjeri elders the Dutigallar Deed, the words we now know as Batman’s Treaty. </p><p>Barak later becomes a powerful political leader of the Wurundjeri, based ",
            "at the Aboriginal station at Coranderrk near present-day Healesville, and a great artist. </p><p>His friend and political supporter, Ann Bon, describes how Barak habitually paints outdoors, working on a sheet of paper attached to the outside of the chimney of his hut at Coranderrk. </p><p>These paintings can be seen as memory systems—an attempt to picture important ",
            "Wurundjeri rituals and historical events. The patterning in these paintings evokes the rhythmic movements of ceremonial singing and dancing, which are themselves—like Barak’s paintings—ways to tell collective stories and enact beliefs. </p><p>In many of these paintings the image of fire is at the centre of the gathering, its rhythmic movements upwards ",
            "towards the sky echoing the dancing and singing animated around it. </p><p>This monument—bricks for a fireplace, an obelisk imagined as a chimney—is a form to mark the life that exists here before Batman’s arrival, the life we see in Barak’s images of dancing and singing around fire. It is a form for our future gatherings, for new meetings, for new images and words. ",
            <img
                src={image4}
                width="400"
                height="2847"
                class="artwork-image"
                alt=""
            />,
        ],
        font: "TN Circumspice",
    },
    {
        title: "Monument to a snapshot of an extinguished fire",
        content: [
            "The city of Melbourne’s foundational document, the deed we know as Batman’s Treaty, is one part of a complex contest AROUND sovereignty. </p><p>During the 1950s and 1960s, Batman’s Treaty, with its implicit recognition of Aboriginal",
            "sovereignty, was sometimes invoked in assertions of Aboriginal rights by such Aboriginal political leaders as Sir Doug Nicholls. </p><p>More recent assertions of Aboriginal sovereignty—such as the ongoing Aboriginal Embassy in Canberra, on Ngunnawal country, established in 1972 and ",
            "sustained by Aboriginal activists such as Paul Coe, Gary Foley, Billy Craigie and Isabel Coe—have rejected the Treaty as nothing more than a crude land grab. </p><p>Uncle Bejam Dennis Walker’s treaty template asserted Aboriginal radical title and enabled non-Aboriginal individuals to treaty directly with relevant ",
            "Aboriginal polities, an uncanny echo of the very features of Batman’s Treaty that the Crown would not accept. </p><p>An image of Batman’s Treaty recurred in strange circumstances on 26 August 1975 at the ceremony to mark the hand-back of the Wave Hill Station to mark the hand-back of the Wave Hill ",
            "Station to the Gurindji people after a nine-year strike and land rights struggle led by Gurindji elder Vincent Lingiari. Prime Minister Gough Whitlam poured a handful of soil into Lingiari’s hands, a symbolic gesture suggested to Whitlam by his advisor Dr H.C. (Nugget) Coombs, who knew the gesture from re-",
            "enactments of Batman’s Treaty in Melbourne during the 1960s. Despite the promise of Whitlam’s symbolic gesture, and an explicit undertaking by Labor’s following Prime Minister, Bob Hawke, when he responded to the Barunga Statement on 12 June 1988, there is no treaty between Aboriginal people and the Crown ",
            "which formally and legally accounts for the process of invasion and dispossession THAT began in 1788. </p><p>In 2006, Camp Sovereignty was established by Aboriginal activists such as Robbie Thorpe and supporters, an occupation of King’s Domain in inner Melbourne, built around a fire WHICH ",
            "was the occupation’s physical and symbolic centre. Designed to coincide with the 2006 Commonwealth Games, the occupation had as one of its three demands (along with Genocide to end, and Sovereignty to be acknowledged) Treaty to be made. Victoria Police violently cleared the camp on the night of 10 May 2006 ",
            "and extinguished the Camp Sovereignty fire. </p><p>Taken by the writer and historian Tony Birch in the days after the Camp was cleared, this photograph shows a rectangular section of grass carefully laid over the trace of the fire by the City of Melbourne. </p><p>This monument is an obelisk to ",
            "this snapshot, this moment captured when we can still see the seams of an effacement that would insist that nothing happened. It is a free-standing chimney for our public space, a form that would grow upwards as the spectre of the rectangle in this photograph, a monument that would house another fire.",
            <img
                src={image5}
                width="4000"
                height="2811"
                class="artwork-image"
                alt=""
            />,
        ],
        font: "TN Ormond",
    },
    {
        title: "Monument to words and deeds",
        content: [
            "</p><p>This work is and addresses words. </p><p>It addresses the words OF our origins, the words of our home-making. This monument commemorates the words of two documents from Melbourne’s beginnings: Batman’s Treaty and Bourke’s Proclamation. </p><p>Batman’s Treaty, also known as the Melbourne Deed, or the Duttigallar Treaty, is the name given to the feoffment, a type of deed of medieval ",
            "European origins, that John Batman claimed he signed with Wurundjeri elders on 6 June 1835. The deed describes an exchange of 240,000 hectares of Wurundjeri land to Batman’s Port Phillip Association for blankets, knives, looking-glasses, tomahawks, beads, scissors, flour and an annual tribute. </p><p>It is not clear if any encounter between Batman’s party and Wurundjeri elders actually occurred on that date, nor, if indeed there was anencounter, what level of mutual comprehension was achieved. ",
            "</p><p>William Buckley, the escaped convict who for 32 years lived with Wathaurung people on the Bellarine Peninsula, dismissed Batman’s claim, writing that Aboriginal people ‘have no chiefs claiming or possessing any superior right over the soil: theirs only being as the heads of families. I therefore looked upon the land dealing spoken of as another hoax of the white man.’ </p><p>It is clear that the Treaty’s premise of private land ownership lies outside the values of Wurundjeri culture, ",
            "and also that, even if it had been possible to explain this premise across language barriers, Wurundjeri elders would not have agreed. </p><p>It is certain that a second deed, generally known as the Geelong Treaty, was fabricated by Batman upon his return to Launceston in June 1835. </p><p>The Port Phillip Association argued for Batman’s Treaty to be validated by the Crown, but met with skeptical responses in Hobart, London and Sydney. ",
            "The British Colonial Office accepted neither the right of the Association to treaty, nor the sovereignty of the Wurundjeri, implicit in Batman’s Treaty. </p><p>The most significant response came from the Governor of New South Wales, Richard Bourke, whose jurisdiction at that point extended to the territories claimed by the Port Phillip Association in the Treaty. Bourke not only re-asserted this jurisdiction, but in his Proclamation of 28 August 1835 also exceeded the extent of the Colonial ",
            "Office’s refusal of the Treaty. Bourke’s Proclamation involved a novel claim—that British sovereignty automatically extended to all of the continent and all of its inhabitants simply by virtue of the words with which the Crown stated this to be so. This response to Batman’s Treaty altered the way British sovereignty in Australia had previously been formulated, and became the basis of the doctrine of terra nullius. </p><p>Batman’s Treaty precipitated de facto the settlement of the ",
            "country around Narrm, or the Port Phillip district, and forced upon Bourke a process of public auction. </p><p>Bourke’s Proclamation and its implications precipitated one of the most rapid and violent processes of dispossession in the history of the British Empire. Entrepreneurs invaded and seized the fertile grazing lands of THE Port Phillip District, later the state of Victoria. </p><p>These bricks—a free-standing chimney for our streets, for our collective space—are for those beginnings. ",
            "They commemorate the origins of our home-making in this place, through the words of those two documents, Batman’s Treaty and Bourke’s Proclamation. </p><p>Each brick bears one word from these documents, inscribed upon its surface, a surface we do not see. This chimney becomes a transcription, its bricklaying. </p><p>the laying of words upon words, those two documents built into a single form, a sentence around which to gather, a hearth of words and deeds. ",
            "</p><p>‘Whereas, it has been represented to me, that diverse of His Majesty’s Subjects have taken possession of vacant Lands of the Crown, within the limits of this Colony, under the pretence of a treaty, bargain, or contract, for the purchase thereof, with the Aboriginal Natives; Now therefore, I, the Governor, in virtue and in exercise of the power and authority in me vested, do hereby proclaim and notify to all His Majesty’s Subjects, and others whom it may concern, that every such treaty, bargain, and contract with the Aboriginal Natives, as ",
            "aforesaid, for the possession, title, or claim to any Lands lying and being within the limits of the Government of the Colony of New South Wales, as the same are laid down and defined by His Majesty’s Commission; that is to say, extending from the Northern Cape, or extremity of the Coast called Cape York, in the latitude of ten degrees thirty seven minutes South, to the Southern extremity of the said Territory of New South Wales, or Wilson’s Promontory, in the latitude of thirty nine degrees twelve minutes South, and embracing all the Country inland to the ",
            "Westward, as far as the one hundred and twenty ninth degree of east longitude, reckoning from the meridian of Greenwich, including all the Islands adjacent, in the Pacific Ocean within the latitude aforesaid, and including also Norfolk Island, is void and of no effect against the rights of the Crown; and that all Persons who shall be found in possession of any such Lands as aforesaid, without the license or authority of His Majesty’s Government, for such purpose, first had and obtained, will be considered as trespassers, and liable to be dealt with in ",
            "like manner as other intruders upon the vacant Lands of the Crown within the said Colony. Given under my Hand and Seal, at Government House, Sydney, this twenty sixth Day of August, One Thousand Eight Hundred and Thirty Five.’ </p><p>‘Know all persons that we three Brothers Jagajaga, Jagajaga, Jagajaga, and also Cooloolock Bungarie, Yanyan, Moowhip and Mommarmalar, being the Principal Chiefs of a certain Native Tribe called Dutigallar, situate and near Port Phillip, called by us. The above mentioned ",
            "Chiefs Iramoo being possessed of the Tract of Land hereinafter mentioned, for and in consideration of twenty pair of blankets, thirty tomahawks, one hundred knives, fifty pair of scissors, thirty looking glasses, two hundred handkerchiefs, one hundred pounds of flour and six shirts delivered to us by John Batman residing in Van Dieman’s Land Esquire but at present sojourning with us and our Tribe do for ourselves our heirs and successors give, grant, enfeoff and confirm unto the said John Batman his heirs and assigns all that tract of country situate and ",
            "being at Port Phillip, running from the branch of the river at the top of the port about 7 miles from the mouth of the river, forty miles north east and from thence—west forty miles across Iramoo Downs or Plains and from thence south south west across Mount Vilanmarnartar to Geelong harbour at the head of the same and containing about 500,000 acres more or less as the same hath been before the execution of these presents delineated and marked out by us according to the custom of our tribe by certain marks made upon the trees growing along the boundaries ",
            "of the said tract of land to hold the said tract of land with all advantages belonging thereto unto and to the use of the said John Batman his heirs and assigns for ever to the intent that the said John Batman his heirs and assigns may occupy and possess the said tract of land and place thereon sheep and cattle yielding and delivering to us and our heirs or successors the yearly rent or tribute of one hundred pair of blankets, one hundred knives, one hundred toma-hawks, fifty suits of clothing, fifty looking glasses, fifty pair of scissors and five tons of flour. In witness ",
            "whereof we Jagajaga, Jagajaga, Jagajaga the above-mentioned Principal Chiefs and Cooloolock, Bungarie, Yanyan, Moowhip and Mommarmalar the chiefs of the said tribe have hereunto affixed our seals to these presents and have signed the same. Dated according to the Christian Era this 6th day of June, One Thousand Eight Hundred and Thirty Five.’ ",
        ],
        font: "TN Marshal",
    },
];

export default pages;
