const Page = ({ content, className, title, style }) => {
    const contentFixed =
        typeof content === "string"
            ? `<p>${content.toUpperCase()}</p>`
            : content;
    return (
        <div className={className} style={style}>
            <div className="flex flex-col items-center justify-center w-full py-12 lg:min-h-screen lg:p-12 mobile-height">
                <div className="w-full text-lg leading-5 lg:leading-8 lg:text-3xl text-[#1A1919] stroke text-left flex justify-center items-center">
                    <div className="relative max-w-[58rem] p-12 py-16 lg:p-16 lg:py-24">
                        <div class="my-auto">
                            {title && (
                                <div className="pb-8 text-center">
                                    {title.toUpperCase()}
                                </div>
                            )}
                            {typeof content === "string" ? (
                                <div
                                    className="paragraphs-text"
                                    dangerouslySetInnerHTML={{
                                        __html: contentFixed,
                                    }}
                                ></div>
                            ) : (
                                content
                            )}
                        </div>
                        <div className="absolute w-2 h-2 border border-white rounded-full top-4 left-4 md:h-3 md:w-3"></div>
                        <div className="absolute w-2 h-2 border border-white rounded-full top-4 right-4 md:h-3 md:w-3"></div>
                        <div className="absolute w-2 h-2 border border-white rounded-full bottom-4 left-4 md:h-3 md:w-3"></div>
                        <div className="absolute w-2 h-2 border border-white rounded-full bottom-4 right-4 md:h-3 md:w-3"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Page };
